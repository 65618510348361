body {
  font-family: var(--font-roboto-sans);
}
* {
  margin: 0;
  font-size: unset;
  font-weight: unset;
}

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  .page-content {
    padding: 1.5rem;

    & > *:not(:last-child),
    & > form:only-child > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
